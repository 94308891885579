class Header {
  constructor(options) {

    var defaults = {
      breakpoint: 284 - 72,
	  seconds: 12
    };

    this.params = Object.assign({}, defaults, options || {});

    this.body = document.body;
    this.setupStickyHeader();
	this.keepStickyHeader();
	this.setMenuTogglers();
  }

  setMenuTogglers() {

	let menuContainer = document.querySelector('#menu-sitemap');

	if (menuContainer) {
	
		let listTogglers = menuContainer.querySelectorAll('.toggle-link');

		if (listTogglers.length) {

			listTogglers.forEach((toggler, index) => {
				toggler.addEventListener('click', function () {
				this.parentNode.classList.toggle('submenu-visible');
				}, true);
			});
		}
	}
  }

  keepStickyHeader() {
	
	let time = this.params.seconds * 1000;

	document.addEventListener('DOMContentLoaded', function () {
		setTimeout(function () { 
			document.body.classList.add('unpin'); 
		}, time);
	}, false);

  }
  

  setupStickyHeader() {

	return;

    window.addEventListener('scroll', (e) => {
	
		var scroll = window.scrollY;

      if (scroll >= this.params.breakpoint - 200) {
        this.body.classList.add('hide-header');
		this.body.classList.remove('basic-header');
      } else {
		this.body.classList.remove('hide-header');
		this.body.classList.add('basic-header');
	  }

	  if (scroll >= this.params.breakpoint - 100) {
        this.body.classList.add('fixed-header');
      } else {
		this.body.classList.remove('fixed-header');
	  }

	  if (scroll >= this.params.breakpoint) {
		this.body.classList.add('sticky-header');
	  }
	  else {
		this.body.classList.remove('sticky-header');
      }
    });
  }
}

export {
  Header as
  default
};
