class ScrollTracker {

  constructor(options) {
    var defaults = {
      elementSelector: '#ad-position-274'
    };
    this.params = Object.assign({}, defaults, options || {});
    this.element = document.querySelector(this.params.elementSelector);
    this.percentage = 0;
    this.passiveSupported = false;
    this.tracked = false;
    this.detectPassiveListenersSupport();
    this.addEvents();
  }


  detectPassiveListenersSupport() {
    try {
      const options = {
        get passive() {
          this.passiveSupported = true;
          return false;
        }
      };

      window.addEventListener("test", null, options);
      window.removeEventListener("test", null, options);
    } catch (err) {
      this.passiveSupported = false;
    }
  }

  getElementOffsetTop(element) {
    let offset = 0;
    while (element) {
      offset += (element.offsetTop);
      element = element.offsetParent;
    }
    return offset;
  }

  addEvents() {

    window.addEventListener('scroll', () => {

      const offset = this.getElementOffsetTop(this.element);

      let percentage = Math.round((window.scrollY) / (offset - window.innerHeight) * 100);

      if (percentage > 100) {
        percentage = 100;
      }

      if (percentage > this.percentage) {
        this.percentage = percentage;
      }

      if (this.percentage === 100 && !this.tracked) {
        this.tracked = true;
        this.track();
      }

    }, this.passiveSupported ? {
      passive: true
    } : false);

    window.addEventListener('beforeunload', (event) => {
      if (!this.tracked) {
        this.tracked = true;
        this.track();
      }
    });

  }

  track() {

    if (!window.gtag) {
      return;
    }
	
	gtag('event', window.location.pathname, {
		'event_category': 'Scroll',
		'event_label': this.percentage,
		'metric5': this.percentage,
  		'non_interaction': true,
    	'transport_type': 'beacon'
	});

  }

}

export {
  ScrollTracker as
  default
};
