class Tabs {
  constructor(options) {
    var defaults = {
      tabSelector: '.tab',
      blockSelector: '.tab-content',
      activeClass: 'tab-active'
    };
    this.params = Object.assign({}, defaults, options || {});
    this.container = document.querySelector(this.params.selector);
    this.tabs = this.container.querySelectorAll(this.params.tabSelector);
    this.blocks = this.container.querySelectorAll(this.params.blockSelector);
    this.active = 0;
    this.addEvents();
  }

  addEvents() {
    for (var index = 0; index < this.tabs.length; index++) {
      this.addTriggerEvent(index);
    }
  }

  addTriggerEvent(index) {
    this.tabs[index].addEventListener('click', (event) => {
      event.preventDefault();
      this.update(index);
    });
  }

  update(active) {

    this.container.classList.remove(this.params.activeClass + '-' + this.active);
    this.active = active;
    this.container.classList.add(this.params.activeClass + '-' + this.active);

    this.tabs.forEach((tab, index) => {
      if (index === this.active) {
        tab.classList.add(this.params.activeClass);
      } else {
        tab.classList.remove(this.params.activeClass);
      }
    });

    this.blocks.forEach((block, index) => {
      if (index === this.active) {
        block.classList.add(this.params.activeClass);
      } else {
        block.classList.remove(this.params.activeClass);
      }
    });
  }
}

export {
  Tabs as
  default
};
