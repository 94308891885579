import axios from 'axios';

class Textlinks {
  constructor(options) {

    var defaults = {
      items: [],
      containerId: 'text-links'
    };

    this.params = Object.assign({}, defaults, options || {});
    this.container = document.getElementById(this.params.containerId);
    this.start();
  }

  start() {

    if (!this.container) {
      return;
    }

    window.TextLinks = {};
    window.TextLinks.render = (personalized) => {
      this.render(personalized);
    };
  }

  renderItem(item) {

    let buffer = `<div class="textlink" id="textlink-${item.id}"><a href="${item.redirect_link}" target="_blank" rel="sponsored nofollow noopener">`;

    if (item.image) {
      buffer += `<div class="item-media"><img width="120" height="60" loading="lazy" src="${item.image}" alt="${item.title_link}"></div>`;
    }

    buffer += `<span class="item-title">${item.textlink_desc}</span>`;

    if (item.track_link) {
      buffer += `<img src="${item.track_link}" style="display:none" width="1" height="1" alt="" />`;
    }

    buffer += `</a></div>`;

    return buffer;
  }

  render(personalized) {

    let buffer = '';

    const items = this.params.items;

    for (let i = 0; i < items.length; i++) {

      if (!personalized && items[i].track_link) {
        continue;
      }

      buffer += this.renderItem(items[i]);
    }

    const html = `${buffer}`;
    this.container.innerHTML = html;
  }

}

export {
  Textlinks as
  default
};
