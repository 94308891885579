class Widget {
  constructor(options) {
    var defaults = {
      widthInputSelector: '#setWidth',
      heightInputSelector: '#setHeight',
      textareaSelector: '#newsWidget',
      widgetSelector: '#nbNews'
    };
    this.params = Object.assign({}, defaults, options || {});
    this.width = 300;
    this.minWidth = 200;
    this.maxWidth = 960;
    this.height = 460;
    this.minWidth = 360;
    this.maxWidth = 800;
    this.class = 't7';
    this.widthInput = document.querySelector(this.params.widthInputSelector);
    this.heightInput = document.querySelector(this.params.heightInputSelector);
    this.textarea = document.querySelector(this.params.textareaSelector);
    this.widget = document.querySelector(this.params.widgetSelector);
    this.addEvents();
  }

  addEvents() {

    this.widthInput.addEventListener('change', (event) => {
      var width = parseInt(this.widthInput.value);
      if (!width) {
        return;
      }
      if (width < this.params.minWidth) {
        this.width = this.params.minWidth;
      } else if (width > this.params.maxWidth) {
        this.width = this.params.maxWidth;
      } else {
        this.width = width;
      }
      this.widthInput.value = width;
      this.update();
    });

    this.heightInput.addEventListener('change', (event) => {
      var height = parseInt(this.heightInput.value);
      if (!height) {
        return;
      }
      if (height < this.params.minHeight) {
        this.height = this.params.minHeight;
      } else if (height > this.params.maxHeight) {
        this.height = this.params.maxHeight;
      } else {
        this.height = height;
      }
      this.heightInput.value = height;
      this.update();
    });

    this.textarea.addEventListener('click', (event) => {
      this.textarea.focus();
      this.textarea.select();
    });

  }

  getCode() {
    return '<div id="nb-root" style="width:' + this.width + 'px;height:' + this.height + 'px;" class="' + this.class + '"><a href="https:\/\/www.newsbomb.gr\/oles-oi-eidhseis">Όλες οι Ειδήσεις<\/a><\/div>\n' + '<script>\n' + '(function(){var es = document.createElement("script"); es.async = true;\n' + 'es.src = "https:\/\/staticnb.bbend.net\/services\/js\/news.js?vs=10"\n' + 'document.getElementById("nb-root").appendChild(es);}());\n' + '<\/script>\n';
  }

  update() {
    this.widthInput.value = this.width;
    this.heightInput.value = this.height;
    if (!this.widget) {
      this.widget = document.querySelector(this.params.widgetSelector);
      if (!this.widget) {
        return;
      }
    }
    this.widget.style.width = this.width + 'px';
    this.widget.style.height = this.height + 'px';
    var code = this.getCode();
    this.widget.innerHTML = code;
    this.textarea.value = code;
  }
}

export {
  Widget as
  default
};
