class Livenow {
  constructor(options) {
    var defaults = {
      containerSelector: '#sticky-video',
      videoPlayerSelector: '#sticky-video-player',
      togglerSelector: '#sticky-video-toggler'
    };
    this.params = Object.assign({}, defaults, options || {});
    this.container = document.querySelector(this.params.containerSelector);
    this.videoPlayer = document.querySelector(this.params.videoPlayerSelector);
    this.toggler = document.querySelector(this.params.togglerSelector);
    this.start();
  }

  start() {

    const videoId = this.container.dataset.videoid;
    const autoplay = this.container.dataset.autoplay;

    let src = 'https://www.youtube.com/embed/' + videoId + '?enablejsapi=1&origin=https://www.newsbomb.gr';
    if (autoplay) {
      src += '&autoplay=1';
    }

    const markup = '<iframe id="sticky-video-iframe" width="420" height="345" src="' + src + '" data-reembed="{ \'no_replace\': 1 }" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

    this.videoPlayer.innerHTML = markup;
    let opened = true;

    this.toggler.addEventListener('click', (event) => {
      event.preventDefault();
      if (opened) {
        opened = false;
        this.videoPlayer.innerHTML = '';
      } else {
        opened = true;
        this.videoPlayer.innerHTML = markup;
      }
      this.container.classList.toggle('opened');
    });

  }
}

export {
  Livenow as
  default
};
