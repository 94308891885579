import axios from 'axios';
class Tail {
  constructor(options) {
    var defaults = {};
    this.params = Object.assign({}, defaults, options || {});
    this.targetElement = document.querySelector(this.params.targetElement);
    this.url = this.params.url;
    if (this.params.triggerEvent === 'click') {
      this.addClickEvent();
    } else if (this.params.triggerEvent === 'scroll') {
      this.addScrollEvent();
    }
  }
  addClickEvent() {
    this.triggerElement = document.querySelector(this.params.triggerElement);
    this.triggerElement.addEventListener('click', (event) => {
      event.preventDefault();
      this.run();
    }, {
      once: true
    });
  }
  addScrollEvent() {
    this.triggered = false;
    this.scrollOffset = this.params.scrollOffset || 0;
    window.addEventListener('scroll', (event) => {
      if (this.triggered) {
        return;
      }
      if (window.pageYOffset > this.scrollOffset) {
        this.triggered = true;
        this.run();
      }
    });
  }
  run() {
    axios.get(this.url).then((response) => {
        this.targetElement.innerHTML = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export {
  Tail as
  default
};
