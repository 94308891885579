import axios from 'axios';

class ArticleLatestNews {
  constructor(options) {

    const defaults = {};

    this.params = Object.assign({}, defaults, options || {});
    this.paginator = document.querySelector(this.params.paginatorSelector);
    this.target = document.querySelector(this.params.targetSelector);
    this.page = 1;
    this.fetching = false;
    this.start();

  }

  start() {
    this.addEvents();
  }

  addEvents() {
    if (!'IntersectionObserver' in window) {
      return;
    }
    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !this.fetching) {
          this.fetching = true;
          this.target.classList.add('loading');
          this.page++;
          this.fetchItems();
        }
      });
    }, {
      threshold: 0
    });
    this.observer.observe(this.paginator);
  }

  fetchItems() {
    axios.get(this.params.paginationUrl + '&page=' + this.page).then((response) => {
      const div = document.createElement('div');
      div.innerHTML = response.data;
      this.target.append(div);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      this.fetching = false;
      this.target.classList.remove('loading');
    });
  }

}

export {
  ArticleLatestNews as
  default
};
