class LinksTracker {

  constructor(options) {
    var defaults = {
      containerSelector: '.links-tracker-container'
    };
    this.params = Object.assign({}, defaults, options || {});
    this.containers = document.querySelectorAll(this.params.containerSelector);
    this.addEvents();
    this.track();
  }

  addEvents() {
    for (var i = 0; i < this.containers.length; i++) {
      var container = this.containers[i];
      var area = container.dataset.area;
      if (area) {
        this.addTrackEvent(container, area);
      }
    }
  }

  addTrackEvent(container, area) {
    container.addEventListener('click', (event) => {
      if (typeof event.composedPath === 'function') {
        var path = event.composedPath();
        for (var i = 0; i < path.length; i++) {
          var el = path[i];
          if (el.tagName === 'A') {

            var href = '';

            // Internal direct links
            if (el.href && el.host === window.location.host) {
              href = el.href;
            }

            // Taboola links
            if (el.href && el.host === 'trc.taboola.com') {
              href = this.getTaboolaLink(el);
            }

            // Engageya links
            if (el.href && el.host.indexOf('engageya.com') !== -1) {
              href = this.getEngageyaLink(el);
            }

            if (href) {
              var date = new Date();
              var time = date.getTime();
              window.sessionStorage.setItem('trackLinkArea', area);
              window.sessionStorage.setItem('trackLinkTime', time);
              window.sessionStorage.setItem('trackLinkHref', href);
            }

            break;
          }
        }
      }
    });
  }

  getTaboolaLink(el) {

    var link = '';

    if (!el.search) {
      return link;
    }

    if (el.search.indexOf('&') === -1) {
      return link;
    }

    var parts = el.search.split('&');

    for (var i = 0; i < parts.length; i++) {
      if (parts[i].indexOf('redir=') === 0) {
        var parts2 = parts[i].split('=');
        link = decodeURIComponent(parts2[1]);
      }
    }

    return link;
  }

  getEngageyaLink(el) {

    var link = '';

    if (!el.dataset.origUrl) {
      return link;
    }

    link = el.dataset.origUrl;

    return link;
  }

  track() {
    var area = window.sessionStorage.getItem('trackLinkArea');
    var href = window.sessionStorage.getItem('trackLinkHref');
    if (area && href && window.gtag) {
      var link = document.createElement('a');
      link.href = href;
      if (link.host === window.location.host) {
        var time = window.sessionStorage.getItem('trackLinkTime');
        if (time) {
          var date = new Date();
          var now = date.getTime();
          if (now - time < 60 * 1000) {
			window.gtag('event', area, {
				'event_category': 'Internal Link',
				'event_label': link.pathname,
  				'non_interaction': true
			});
          }
        }
      }
    }
    window.sessionStorage.removeItem('trackLinkTime');
    window.sessionStorage.removeItem('trackLinkArea');
    window.sessionStorage.removeItem('trackLinkHref');
  }
}

export {
  LinksTracker as
  default
};
