class Togglers {
  constructor(options) {
    var defaults = {};
    this.params = Object.assign({}, defaults, options || {});
    this.togglers = document.querySelectorAll('.toggler');
    this.setupTogglers();
  }
  setupTogglers() {
    this.togglers.forEach((toggler) => {
      toggler.addEventListener('click', (event) => {
        event.preventDefault();
        var target = toggler.dataset.open;
        document.documentElement.classList.toggle(target + '-opened');
      });
    });
  }
}

export {
  Togglers as
    default
};
