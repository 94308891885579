import axios from 'axios';

class Popular {
  constructor(options) {

    var defaults = {
      url: 'https://www.newsbomb.gr/popular?format=json',
      containerId: 'viral-news',
      limit: 3,
      excludeItem: 0,
      excludeCategories: [31, 95, 99, 103, 106, 113, 114, 115, 116, 117, 118, 119, 120, 124, 127, 131, 132, 136, 139, 159, 38]
    };

    this.params = Object.assign({}, defaults, options || {});
    this.container = document.getElementById(this.params.containerId);

    this.params.excludeItem = parseInt(this.params.excludeItem);
    this.params.excludeCategories = this.params.excludeCategories.map(category => parseInt(category));

    if (this.container) {
      this.start();
    }

  }

  start() {

    axios.get(this.params.url).then((response) => {

      const items = response.data.filter((item) => {
        return parseInt(item.id) !== this.params.excludeItem && this.params.excludeCategories.indexOf(parseInt(item.catid)) === -1 && parseInt(item.external) !== 1;
      });

      const limit = Math.min(this.params.limit, items.length);

      let buffer = '';
      for (let i = 0; i < limit; i++) {
        buffer += this.renderItem(items[i]);
      }

      const html = this.render(buffer);
      this.container.innerHTML = html;

    }).catch((e) => {
      console.log(e);
    });
  }

  renderItem(item) {
    return `<li><a class="item-link cd p10 flat title-14" href="${item.link}"><div class="item-media"><img width="110" height="66" loading="lazy" src="${item.img_thumb}" alt="${item.title_text}"/></div><div class="item-info"><div class="item-details"><h3 class="item-title">${item.title}</h3></div></div></a></li>`;
  }

  render(items) {
    return `<a class="trendig-now-label" href="/popular">ΔΗΜΟΦΙΛΕΙΣ ΕΙΔΗΣΕΙΣ ΤΩΡΑ</a><ul class="trending-now">${items}</ul>`;
  }

}

export {
  Popular as
  default
};
