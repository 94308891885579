//import _ from 'lodash';
import Togglers from './togglers';
import LinksTracker from './linkstracker';
import lazyframe from 'lazyframe';

class Global {
  constructor(options) {
    var defaults = {};
    this.params = Object.assign({}, defaults, options || {});
    this.pageTrackOptions = {}
    this.run();
  }

  run() {

    new Togglers();
    new LinksTracker();
    this.contactForm();
    this.newsFeed();
    // this.timeTracker();
    // this.timeTrackerGreedy();
    // this.scrollTracker();
    this.lazyLoadVideos();
    // this.embeds();
    this.sharingTools();
  }

  scrollTracker() {
    this.tailTracked = false;

    var articleTail = document.getElementById('item-page-tail');

    if (articleTail) {

      this.pageTrackOptions = JSON.parse(articleTail.getAttribute('data-page')) || {};
      this.checkPoint = articleTail.getBoundingClientRect().top + window.scrollY - (window.innerHeight * .25);

      if (window.pageYOffset > this.checkPoint) {
        this.tailTracked = true;
        this.trackPageView();
        return;
      }

      window.addEventListener('scroll', (event) => {
        if (this.tailTracked) {
          return;
        }
        if (window.pageYOffset > this.checkPoint) {
          this.tailTracked = true;
          this.trackPageView();
        }
      });
    }
  }

  trackPageView() {
    if (window.gtag) {

      gtag('event', 'page_view', {
        page_title: this.pageTrackOptions.title,
        page_location: window.location.protocol + '//' + window.location.host + this.pageTrackOptions.link,
        page_path: this.pageTrackOptions.link
      });
    }
    /*
    if (window.AT_hit) {
      window.AT_hit.sendTag("F");
    }
    */
  }

  contactForm() {
    var contactForm = document.getElementById('main-contact-form');
    if (contactForm) {
      var inputs = contactForm.querySelectorAll('.animate-label');
      inputs.forEach((input, index) => {
        input.addEventListener('focus', function () {
          this.parentNode.classList.add('focusin');
        }, true);
        input.addEventListener('blur', function () {
          if (!this.value) {
            this.parentNode.classList.remove('focusin');
          }
        }, true);
      });
    }
  }

  newsFeed() {
    var news = document.getElementById('module-90');
    if (news) {
      let viewport = news.querySelector('.roll');
      let content = news.querySelector('.roll-items-group');
      var running, timeout, timeout2, href;
      viewport.addEventListener('mouseenter', function () {
        timeout2 = window.setTimeout(function () {
          if (document.body.classList.contains('sticky-header')) {
            document.body.classList.add('expand-sticky');
          }
        }, 500);
      });
      viewport.addEventListener('mouseleave', function () {
        if (timeout2) {
          window.clearTimeout(timeout2);
        }
        document.body.classList.remove('expand-sticky');
      });
    }
  }

  lazyLoadVideos() {
    lazyframe('.lazyframe', {
      autoplay: false
    });
  }

  embeds() {

    this.twitterEmbeds = document.querySelectorAll('.embed-twitter');
    this.facebookEmbeds = document.querySelectorAll('.embed-facebook');
    this.instagramEmbeds = document.querySelectorAll('.embed-instagram');
    this.playbuzzEmbeds = document.querySelectorAll('.embed-playbuzz');
    this.tiktokEmbeds = document.querySelectorAll('.embed-tiktok');

    if (!'IntersectionObserver' in window) {

      if (this.twitterEmbeds.length) {
        this.loadTwitter();
      }

      if (this.facebookEmbeds.length) {
        this.loadFacebook();
      }

      if (this.instagramEmbeds.length) {
        this.loadInstagram();
      }

      if (this.playbuzzEmbeds.length) {
        this.loadPlaybuzz();
      }

      if (this.tiktokEmbeds.length) {
        this.loadTiktok();
      }

      return;
    }

    this.twitter = false;
    this.facebook = false;
    this.instagram = false;
    this.playbuzz = false;
    this.tiktok = false;

    this.observer = new IntersectionObserver((entries, observer) => {
      for (x = 0; x < entries.length; x++) {

        if (!this.twitter && entries[x].classList.contains('embed-twitter') && entries[x].isIntersecting) {
          this.twitter = true;
          this.loadTwitter();
        }

        if (!this.facebook && entries[x].classList.contains('embed-facebook') && entries[x].isIntersecting) {
          this.facebook = true;
          this.loadFacebook();
        }

        if (!this.instagram && entries[x].classList.contains('embed-instagram') && entries[x].isIntersecting) {
          this.instagram = true;
          this.loadInstagram();
        }


        if (!this.playbuzz && entries[x].classList.contains('embed-playbuzz') && entries[x].isIntersecting) {
          this.playbuzz = true;
          this.loadPlaybuzz();
        }

        if (!this.tiktok && entries[x].classList.contains('embed-tiktok') && entries[x].isIntersecting) {
          this.tiktok = true;
          this.loadTiktok();
        }

      }
    }, { threshold: 0, rootMargin: '300px' });


    for (i = 0; i < twitterEmbeds.length; i++) {
      this.observer.observe(twitterEmbeds[i]);
    }

    for (i = 0; i < facebookEmbeds.length; i++) {
      this.observer.observe(facebookEmbeds[i]);
    }

    for (i = 0; i < instagramEmbeds.length; i++) {
      this.observer.observe(instagramEmbeds[i]);
    }

    for (i = 0; i < playbuzzEmbeds.length; i++) {
      this.observer.observe(playbuzzEmbeds[i]);
    }

    for (i = 0; i < tiktokEmbeds.length; i++) {
      this.observer.observe(tiktokEmbeds[i]);
    }

  }

  loadTwitter() {
    window.twttr = (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f) {
        t._e.push(f);
      };

      return t;
    }(document, "script", "twitter-wjs"));
  }

  loadFacebook() {
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/el_GR/sdk.js#xfbml=1&version=v2.5";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  loadInstagram() {
    const script = document.createElement('script');
    script.src = '//www.instagram.com/embed.js';
    document.head.appendChild(script);
  }

  loadPlaybuzz() {
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://embed.playbuzz.com/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'playbuzz-sdk'));
  }

  loadTiktok() {
    const script = document.createElement('script');
    script.src = 'https://www.tiktok.com/embed.js';
    document.head.appendChild(script);
  }

  sharingTools() {
    window.shareToMessenger = this.shareToMessenger;
    window.isMobile = this.isMobile;
    window.copyItemUrl = this.copyItemUrl;
  }

  shareToMessenger(url) {
    if (window.isMobile()) {
      window.location.href = 'fb-messenger://share/?app_id=370430459961223&link=' + url;
    } else {
      window.open('https://www.facebook.com/dialog/send?app_id=370430459961223&link=' + url + '&redirect_uri=' + url, '_blank');
    }
  }

  isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return true;
    }

    if (/android/i.test(userAgent)) {
      return true;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }

    return false;
  }

  copyItemUrl(element) {

    if (!navigator.clipboard) {
      return;
    }

    const url = element.dataset.url;

    if (!url) {
      return;
    }

    navigator.clipboard.writeText(url).then(
      () => {
        element.classList.add('copied');
        window.setTimeout(() => {
          element.classList.remove('copied');
        }, 5000);

      }, () => {
        console.log('Copy URL failed');
      }
    );

  }


  /*timeTrackerGreedy() {

    clearInterval(window.utimer);
    var times = 1;
    window.utimer = setInterval(function() {
      if (times > 3) {
        var track_value = (times * 30000) / 60000;
        gtag('event', minute + ' min on page', {
          event_category: 'Timing',
          event_value: 1,
          nonInteraction: false
        });
      }
      times = times + 1;
    }, 30000);
  }

  timeTracker() {
    var TimeTracker = {};
    TimeTracker.start = function() {
      this.duration = 0;
      this.minute = 0;
      this.start = new Date();
      this.addTimeout();
      this.addEvents();
    };
    TimeTracker.track = function() {
      var now = new Date();
      this.duration = now - this.start;
      var minute = parseInt(this.duration / 60000);
      if (minute > this.minute) {
        gtag('event', minute + ' min on page', {
          event_category: 'Timing',
          event_value: 1,
          nonInteraction: false
        });
        this.minute = minute;
      }
    };

    TimeTracker.addEvents = function() {
      document.addEventListener('mouseleave', _.throttle(_.bind(this.track, this), 5000));
    };

    TimeTracker.addTimeout = function() {
      window.setTimeout(_.bind(function() {
        this.track();
        this.addTimeout();
      }, this), 29 * 60000);
    };

    TimeTracker.start();
  }*/


}



export {
  Global as
    default
};
